<template>
  <div class="container">
    <div class="container-header mb-15">
      <div class="header-select">
        <a-form-model layout="inline" :model="formInline">
          <a-form-model-item>
            <a-input v-model="formInline.keyword" placeholder="请输入企业名称" class="w-200 mr-10" allow-clear @keyup.enter.native="search"/>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" class="mr-10" @click="search">搜索</a-button>
            <a-button icon="redo" @click="reset">重置</a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
      <a-button type="primary" icon="plus" @click="showModal">新增</a-button>
    </div>
    <div class="container-content">
      <a-table :columns="columns" :data-source="data" rowKey="enterpriseId" :pagination="pagination" @change="onChange">
        <!--<a-tooltip slot="description" slot-scope="text" placement="topLeft" :mouseEnterDelay="0.3">-->
        <!--  <template slot="title">{{ text }}</template>-->
        <!--  <span>{{ text }}</span>-->
        <!--</a-tooltip>-->
        <span slot="systemName" slot-scope="text, record ">
          <a-avatar :size="20" shape="square" :src="record.systemLogoFileUrl ? record.systemLogoFileUrl : require('@/assets/logo.png')" class="mr-5" />
          <span>{{ text }}</span>
        </span>
        <a-tooltip slot="tel" slot-scope="text" placement="topLeft" :mouseEnterDelay="0.3">
          <template slot="title">{{ text }}</template>
          <span>{{ text }}</span>
        </a-tooltip>
        <a-tooltip slot="email" slot-scope="text" placement="topLeft" :mouseEnterDelay="0.3">
          <template slot="title">{{ text }}</template>
          <span>{{ text }}</span>
        </a-tooltip>
        <a-tooltip slot="websiteUrl" slot-scope="text" placement="topLeft" :mouseEnterDelay="0.3">
          <template slot="title">{{ text }}</template>
          <a :href="text" target="_blank">{{ text }}</a>
        </a-tooltip>
        <template slot="isEnable" slot-scope="text">
          <span>
            <a-tag :color="text === 1? 'green' : 'volcano'">{{ text ? '启用' : '禁用'}}</a-tag>
          </span>
        </template>
        <span slot="updated" slot-scope="text">
          {{ text | dateformat('yyyy-MM-DD HH:mm:ss')}}
        </span>
        <!--<a-tooltip slot="remark" slot-scope="text" placement="topLeft" :mouseEnterDelay="0.3">-->
        <!--  <template slot="title">{{ text }}</template>-->
        <!--  <span>{{ text }}</span>-->
        <!--</a-tooltip>-->

        <span slot="action" slot-scope="text, record, index">
          <a @click="operate(text, record, index, 'detail')">查看</a>
          <!--<a @click="operate(text, index, 'account')">组织架构</a>-->
          <a-divider type="vertical"/>
          <a-dropdown :trigger="['click']" placement="bottomRight">
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">更多<a-icon type="down"/></a>
            <a-menu slot="overlay">
              <a-menu-item key="structure" class="aaa">
                <a @click="operate(text, record, index, 'structure')">组织架构</a>
              </a-menu-item>
              <a-menu-item key="adhibition" class="aaa">
                <a @click="operate(text, record, index, 'adhibition')">应用</a>
              </a-menu-item>
              <!--<a-menu-item key="associated" class="aaa">-->
              <!--  <a @click="operate(text, record, index, 'associated')">绑定</a>-->
              <!--</a-menu-item>-->
              <a-menu-item key="edit">
                <a @click="operate(text, record, index, 'edit')">编辑</a>
              </a-menu-item>
              <a-menu-item key="isEnable" v-if="typeof text.isEnable !== 'undefined'">
                <a @click="operate(text, record, index, 'isEnable')"> {{ text.isEnable === 1 ? '禁用' : '启用' }}</a>
              </a-menu-item>
              <a-menu-item key="refresh">
                <a @click="operate(text, record, index, 'refresh')">刷新数据</a>
              </a-menu-item>
              <a-menu-item key="refreshContext">
                <a @click="operate(text, record, index, 'refreshContext')">刷新上下文配置</a>
              </a-menu-item>
              <a-menu-item key="refreshEsIndex">
                <a @click="operate(text, record, index, 'refreshEsIndex')">刷新ES索引</a>
              </a-menu-item>
              <!--<a-menu-item key="detele">-->
              <!--  <a @click="operate(text, record, index, 'detele')">移除</a>-->
              <!--</a-menu-item>-->
            </a-menu>
          </a-dropdown>
        </span>
      </a-table>
    </div>

    <!--  弹框  -->
    <CompanyModal
      v-if="visible"
      :title="title"
      :readOnly="readOnly"
      :visible="visible"
      :form="form"
      :editType="editType"
      :typeList="typeList"
      @closeModal="closeModal"
    ></CompanyModal>
  </div>
</template>
<a-avatar :size="50" shape="square" icon="user" :src="form.systemLogoFileUrl ? form.systemLogoFileUrl : undefined" />
<script>
import CompanyModal from '@/views/enterprise/components/companyModal'
import {
  enterpriseList,
  enterpriseDetail,
  usableStatusModify,
  refreshEsOrders,
  refreshAppContextConfig, refreshEsIndex
} from '@/api/enterprise'
const columns = [
  {title: 'id', dataIndex: 'enterpriseId', key: 'enterpriseId', width: 80},
  {title: '企业名称', dataIndex: 'enterpriseName', key: 'enterpriseName' },
  {title: '系统名称', dataIndex: 'systemName', key: 'systemName', scopedSlots: {customRender: 'systemName'}},
  // {title: '简介', dataIndex: 'description', key: 'description', scopedSlots: {customRender: 'description'}, ellipsis: true},
  {title: '电话', dataIndex: 'tel', key: 'tel', scopedSlots: {customRender: 'tel'}, ellipsis: true, width: 120},
  {title: '邮箱', dataIndex: 'email', key: 'email', scopedSlots: {customRender: 'email'}, ellipsis: true},
  {title: '网址', dataIndex: 'websiteUrl', key: 'websiteUrl', scopedSlots: {customRender: 'websiteUrl'}, ellipsis: true},
  {title: '状态', dataIndex: 'isEnable', key: 'isEnable', scopedSlots: {customRender: 'isEnable'}, width: 100},
  // {title: '更新时间', dataIndex: 'updated', key: 'updated', scopedSlots: {customRender: 'updated'}},
  // {title: '备注', dataIndex: 'remark', key: 'remark', scopedSlots: {customRender: 'remark'}, ellipsis: true},
  {title: '操作', key: 'action', scopedSlots: {customRender: 'action'}, width: 120}
];

export default {
  name: 'CompanyList',
  components: {
    CompanyModal
  },
  data() {
    return {
      data: [],
      columns,
      formInline:{
        keyword: ''
      },
      title: '',
      visible: false,
      form: {},
      typeList:{
        // 主体类型
        subjectTypeList: {
          1: '个人',
          2: '单位'
        },
        // 账户类型
        accountTypeList: {
          1: '银行卡',
          2: '支付宝',
          3: '微信',
        }
      },
      readOnly: false,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '50', ],
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total) => `共 ${total} 条`
      },
      editType: ''

    }
  },
  mounted() {
    this.getEnterpriseList()
  },
  methods: {
    getEnterpriseList() {
      const data = {
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        param: {
          keyword: this.formInline.keyword || '',
          platformType: '0'
        },
        orders: [
          {
            field: 'enterprise_id',
            desc: true,
          }
        ]
      }
      enterpriseList(data).then((res) => {
        if (res['code'] !== 0) {
          this.$antMessage.error(res['message'])
          return
        }
        this.data = res.data.list
        this.pagination.current = res.data['pageNum']
        this.pagination.pageSize = res.data['pageSize']
        this.pagination.total = res.data['total']
      })

    },
    // 搜索
    search() {
      this.getEnterpriseList()
    },
    // 重置
    reset() {
      this.formInline = {
        keyword: undefined
      }
      this.getEnterpriseList()
    },
    // 操作列按钮
    operate(text, record, index, type) {
      let data
      const that = this
      switch (type) {
        case 'detail':
          this.title = '查看'
          enterpriseDetail({enterpriseId: record.enterpriseId}).then((res) => {
            if (res['code'] !== 0) {
              this.$antMessage.error(res['message'])
              return
            }
            this.form = res.data
            this.readOnly = true
            this.visible = true
          })
          break
        case 'structure':
          this.$router.push({
            name: 'StructureDetail',
            params: {
              id: text.enterpriseId,
              name: text.enterpriseName
            }
          })
          break
        case 'adhibition':
          this.$router.push({
            name: 'AdhibitionList',
            params: {
              id: text.enterpriseId,
              name: text.enterpriseName
            }
          })
          break
        case 'edit':
          this.title = '编辑'
          this.editType = 'edit'
          enterpriseDetail({enterpriseId: record.enterpriseId}).then((res) => {
            if (res['code'] !== 0) {
              this.$antMessage.error(res['message'])
              return
            }
            this.form = res.data
            this.readOnly = false
            this.visible = true
          })
          break
        case 'isEnable':
          this.$confirm({
            content: '是否' + (text.isEnable === 1 ? '禁用' : '启用') + '该企业？',
            onOk() {
              data = {
                enterpriseIds: [text.enterpriseId],
                isEnable: text.isEnable === 1 ? 0 : 1
              }
              usableStatusModify(data).then((res) => {
                if (res['code'] !== 0) {
                  return
                }
                that.getEnterpriseList()
                that.$antMessage.success(res['message'])
              })
            },
            cancelText: '取消',
            onCancel() {
              that.$destroyAll()
            }
          })
          break
        case 'associated':
          break
        case 'detele':
          setTimeout(() => {
            this.$message.success('移除成功')
          }, 1000);
          break
        case 'refresh':
          this.$confirm({
            content: '是否刷新该企业的全部数据？',
            onOk() {
              data = {
                enterpriseId: text.enterpriseId
              }
              refreshEsOrders(data).then((res) => {
                if (res['code'] !== 0) {
                  return
                }
                that.$antMessage.success(res['message'])
              })
            },
            cancelText: '取消',
            onCancel() {
              that.$destroyAll()
            }
          })
          break
        case 'refreshContext':
          this.$confirm({
            content: '是否刷新该企业的上下文配置？',
            onOk() {
              data = {
                enterpriseId: text.enterpriseId
              }
              refreshAppContextConfig(data).then((res) => {
                if (res['code'] !== 0) {
                  return
                }
                that.$antMessage.success(res['message'])
              })
            },
            cancelText: '取消',
            onCancel() {
              that.$destroyAll()
            }
          })
          break
        case 'refreshEsIndex':
          this.$confirm({
            content: '是否刷新该企业的ES索引？',
            onOk() {
              data = {
                enterpriseId: text.enterpriseId
              }
              refreshEsIndex(data).then((res) => {
                if (res['code'] !== 0) {
                  return
                }
                that.$antMessage.success(res['message'])
              })
            },
            cancelText: '取消',
            onCancel() {
              that.$destroyAll()
            }
          })
          break
      }
    },
    showModal() {
      this.title = '新增'
      this.editType = 'add'
      this.readOnly = false
      this.form = {
        enterpriseName: '',
        loginLogoFileId: '',
        exportLogoFileId: '',
        systemLogoFileId: null,
        systemName: '',
        description: '',
        remark: '',
        address: '',
        fileCode: '',
        tel: '',
        fax: '',
        postCode: '',
        email: '',
        websiteUrl: '',
        taxNo: '',
      }
      this.visible = true;
    },
    onChange(pagination) {
      this.pagination = pagination
      this.getEnterpriseList()
    },
    closeModal() {
      this.getEnterpriseList()
      this.visible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-select {

    }
  }

  .container-content {
    /deep/ .ant-table-wrapper {

    }
  }

  .w-200 {
    width: 200px;
  }
}

/deep/ .ant-dropdown-menu-item {
  & > a {
    margin: -5px 0px;
    color: #1890ff;
  }
}

/deep/ .ant-modal-wrap.detail-modal {
  .ant-form-item {
    margin-bottom: 0px;
    .form-text {
      line-height: initial;
      margin-top: 10px;
    }
  }
}
/deep/ .ant-modal-wrap.edit-modal{
  .ant-modal-content{
    .ant-form-item{
      width: 100%;
      margin-bottom: 12px;
      .ant-form-item-label{
        width: 80px;
      }
      .ant-form-item-control-wrapper{
        width: calc( 100% - 80px );
      }
    }
  }

}
</style>
