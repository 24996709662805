<template>
  <div>
    <div v-if="readOnly">
      <a-modal
          wrapClassName="detail-modal"
          :title="title"
          :visible="visible"
          :confirm-loading="confirmLoading"
          width="50%"
          :footer="readOnly ? null : undefined"
          @cancel="(e) => resetForm(e, 'ruleForm')"
      >
        <a-form-model
            ref="ruleForm"
            layout="inline"
            :model="form"
            :label-col="{span: 5}"
            :wrapper-col="{span: 18}"
        >
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="系统logo" prop="systemLogoFileUrl">
                <a-avatar :size="50" shape="square" :src="form.systemLogoFileUrl ? form.systemLogoFileUrl : require('@/assets/logo.png')" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="10">
            <a-col :span="24">
              <a-form-model-item label="企业名称" prop="enterpriseName">
                {{ form.enterpriseName }}
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="10">
            <a-col :span="24">
              <a-form-model-item label="系统名称" prop="systemName">
                {{ form.systemName }}
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="简介" prop="description">
                {{ form.description }}
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item ref="tel" label="电话" prop="tel">
                {{ form.tel }}
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item ref="fax" label="传真" prop="fax">
                {{ form.fax }}
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item ref="email" label="邮箱" prop="email">
                {{ form.email }}
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item ref="postCode" label="邮编" prop="postCode">
                {{ form.postCode }}
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item ref="websiteUrl" label="网址" prop="websiteUrl">
                {{ form.websiteUrl }}
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="地址" prop="address">
                {{ form.address }}
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="备注" prop="remark">
                {{ form.remark }}
              </a-form-model-item>
            </a-col>
          </a-row>
          <!--<template v-for="(item, index) in form.enterpriseAccountDetailList">-->
          <!--  &lt;!&ndash;<div :key="i">账户{{ i }}</div>&ndash;&gt;-->
          <!--  <div :key="index" class="list-title pt-10">-->
          <!--    <span class="title-name">账户{{ index +1 }}</span>-->
          <!--    <a-button v-if="form.enterpriseAccountDetailList && form.enterpriseAccountDetailList.length > 1 " type="danger" size="small" icon="delete" ghost @click="deleteDepartment(index)">删除</a-button>-->
          <!--  </div>-->
          <!--  <div :key="index + '.'" class="account-list">-->
          <!--    <a-row :gutter="10">-->
          <!--      <a-col :span="12">-->
          <!--        <a-form-model-item-->
          <!--          label="主体类型"-->
          <!--          :key="index+'.subjectType'"-->
          <!--          :prop="'enterpriseAccountDetailList.' + index + '.subjectType'"-->
          <!--          :rules="{ required: true, message: '请选择主体类型', trigger: 'change' }"-->
          <!--        >-->
          <!--          <a-select v-model="item.subjectType" placeholder="请选择主体类型">-->
          <!--            <a-select-option v-for="(i, key, index) in typeList.subjectTypeList" :value="key" :key="index">{{ i }}-->
          <!--            </a-select-option>-->
          <!--          </a-select>-->
          <!--        </a-form-model-item>-->
          <!--      </a-col>-->
          <!--      <a-col :span="12">-->
          <!--        <a-form-model-item-->
          <!--          label="账户类型"-->
          <!--          :key="index+'.accountType'"-->
          <!--          :prop="'enterpriseAccountDetailList.' + index + '.accountType'"-->
          <!--          :rules="{ required: true, message: '请选择账户类型', trigger: 'change' }"-->
          <!--        >-->
          <!--          <a-select v-model="item.accountType" placeholder="请选择账户类型">-->
          <!--            <a-select-option v-for="(i, key, index) in typeList.accountTypeList" :value="key" :key="index">{{ i }}-->
          <!--            </a-select-option>-->
          <!--          </a-select>-->
          <!--        </a-form-model-item>-->
          <!--      </a-col>-->
          <!--    </a-row>-->
          <!--    <a-row :gutter="10">-->
          <!--      <a-col :span="12">-->
          <!--        <a-form-model-item-->
          <!--          ref="accountName"-->
          <!--          label="账户名称"-->
          <!--          :key="index+'.accountName'"-->
          <!--          :prop="'enterpriseAccountDetailList.' + index + '.accountName'"-->
          <!--          :rules="{ required: true, message: '请输入账户名称', trigger: 'change' }"-->
          <!--        >-->
          <!--          <a-input v-model="item.accountName" @blur="() => { $refs.accountName.onFieldBlur() }"/>-->
          <!--        </a-form-model-item>-->
          <!--      </a-col>-->
          <!--      <a-col :span="12">-->
          <!--        <a-form-model-item-->
          <!--          ref="bankName"-->
          <!--          label="银行名"-->
          <!--          :key="index+'.bankName'"-->
          <!--          :prop="'enterpriseAccountDetailList.' + index + '.bankName'"-->
          <!--          :rules="{ required: true, message: '请输入银行名', trigger: 'change' }"-->
          <!--        >-->
          <!--          <a-input v-model="item.bankName" @blur="() => { $refs.bankName.onFieldBlur() }"/>-->
          <!--        </a-form-model-item>-->
          <!--      </a-col>-->
          <!--    </a-row>-->
          <!--    <a-row :gutter="10">-->
          <!--      <a-col :span="12">-->
          <!--        <a-form-model-item-->
          <!--          ref="accountNo"-->
          <!--          label="账户"-->
          <!--          :key="index+'.accountNo'"-->
          <!--          :prop="'enterpriseAccountDetailList.' + index + '.accountNo'"-->
          <!--          :rules="{ required: true, message: '请输入账户', trigger: 'change' }"-->
          <!--        >-->
          <!--          <a-input v-model="item.accountNo" @blur="() => { $refs.accountNo.onFieldBlur() }"/>-->
          <!--        </a-form-model-item>-->
          <!--      </a-col>-->
          <!--      <a-col :span="12">-->
          <!--        <a-form-model-item-->
          <!--          ref="isEnable"-->
          <!--          label="状态"-->
          <!--          :key="index+'.isEnable'"-->
          <!--          :prop="'enterpriseAccountDetailList.' + index + '.isEnable'"-->
          <!--          :rules="{ required: true, message: '请选择账户状态', trigger: 'change' }"-->
          <!--        >-->
          <!--          <a-radio-group v-model="item.isEnable">-->
          <!--            <a-radio :value="0">禁用</a-radio>-->
          <!--            <a-radio :value="1">启用</a-radio>-->
          <!--          </a-radio-group>-->
          <!--        </a-form-model-item>-->
          <!--      </a-col>-->
          <!--    </a-row>-->
          <!--  </div>-->
          <!--</template>-->
          <!--<a-button v-if="!readOnly" type="dashed" style="margin-left: 60px" @click="addDepartment()">-->
          <!--  <a-icon type="plus-circle" theme="filled" />-->
          <!--  添加账户-->
          <!--</a-button>-->
        </a-form-model>
        <template slot="footer">
          <div>
            <a-button v-if="!readOnly" key="back" @click="(e) => resetForm(e, 'ruleForm')">
              取消
            </a-button>
            <a-button v-if="!readOnly" key="submit" type="primary" @click="(e) => onSubmit( e, 'ruleForm')">
              确认
            </a-button>
          </div>
        </template>
      </a-modal>
    </div>
    <div v-else>
      <a-modal
          wrapClassName="edit-modal"
          :title="title"
          :visible="visible"
          :confirm-loading="confirmLoading"
          width="50%"
          @cancel="(e) => resetForm(e, 'ruleForm')"
      >
        <a-form-model
            ref="ruleForm"
            layout="inline"
            :model="form"
            :rules="rules"
            :label-col="{span: 4}"
            :wrapper-col="{span: 19}"
        >
          <a-row>
            <a-col :span="24">
              <a-form-model-item ref="userName" label="系统logo" prop="fileUrl">
                <a-upload
                  name="file"
                  accept="image"
                  :show-upload-list="false"
                  :before-upload="beforeUpload"
                  :custom-request="customRequest"
                  class="mr-10"
                >
                  <a-avatar v-if="isUpload" :loading="imgLoading" :size="50" shape="square" :src="fileData.fileUrl ? fileData.fileUrl : require('@/assets/logo.png')" />
                  <a-avatar v-else :size="50" :loading="imgLoading" shape="square" :src="form.systemLogoFileUrl ? form.systemLogoFileUrl : require('@/assets/logo.png')" />
                </a-upload>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="10">
            <a-col :span="12">
              <a-form-model-item ref="enterpriseName" label="企业名称" prop="enterpriseName">
                <a-input v-model="form.enterpriseName" @blur="() => { $refs.enterpriseName.onFieldBlur() }"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item ref="systemName" label="系统名称" prop="systemName">
                <a-input v-model="form.systemName" @blur="() => { $refs.systemName.onFieldBlur() }"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="简介" prop="description">
                <a-input v-model="form.description" type="textarea" :rows="3"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="10">
            <a-col :span="12">
              <a-form-model-item ref="tel" label="电话" prop="tel">
                <a-input v-model="form.tel" @blur="() => { $refs.tel.onFieldBlur() }"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item ref="fax" label="传真" prop="fax">
                <a-input v-model="form.fax" @blur="() => { $refs.fax.onFieldBlur() }"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-row :gutter="10">
              <a-col :span="12">
                <a-form-model-item ref="email" label="邮箱" prop="email">
                  <a-input v-model="form.email" @blur="() => { $refs.email.onFieldBlur() }"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item ref="postCode" label="邮编" prop="postCode">
                  <a-input v-model="form.postCode" @blur="() => { $refs.postCode.onFieldBlur() }"/>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-col :span="24">
              <a-form-model-item ref="websiteUrl" label="网址" prop="websiteUrl">
                <a-input v-model="form.websiteUrl" @blur="() => { $refs.websiteUrl.onFieldBlur() }" />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="地址" prop="address">
                <a-input v-model="form.address" type="textarea" :auto-size="{ minRows: 2 }"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-model-item label="备注" prop="remark">
                <a-input v-model="form.remark" type="textarea" :auto-size="{ minRows: 3 }"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <!--<template v-for="(item, index) in form.enterpriseAccountDetailList">-->
          <!--  &lt;!&ndash;<div :key="i">账户{{ i }}</div>&ndash;&gt;-->
          <!--  <div :key="index" class="list-title pt-10">-->
          <!--    <span class="title-name">账户{{ index +1 }}</span>-->
          <!--    <a-button v-if="form.enterpriseAccountDetailList && form.enterpriseAccountDetailList.length > 1 " type="danger" size="small" icon="delete" ghost @click="deleteDepartment(index)">删除</a-button>-->
          <!--  </div>-->
          <!--  <div :key="index + '.'" class="account-list">-->
          <!--    <a-row :gutter="10">-->
          <!--      <a-col :span="12">-->
          <!--        <a-form-model-item-->
          <!--          label="主体类型"-->
          <!--          :key="index+'.subjectType'"-->
          <!--          :prop="'enterpriseAccountDetailList.' + index + '.subjectType'"-->
          <!--          :rules="{ required: true, message: '请选择主体类型', trigger: 'change' }"-->
          <!--        >-->
          <!--          <a-select v-model="item.subjectType" placeholder="请选择主体类型">-->
          <!--            <a-select-option v-for="(i, key, index) in typeList.subjectTypeList" :value="key" :key="index">{{ i }}-->
          <!--            </a-select-option>-->
          <!--          </a-select>-->
          <!--        </a-form-model-item>-->
          <!--      </a-col>-->
          <!--      <a-col :span="12">-->
          <!--        <a-form-model-item-->
          <!--          label="账户类型"-->
          <!--          :key="index+'.accountType'"-->
          <!--          :prop="'enterpriseAccountDetailList.' + index + '.accountType'"-->
          <!--          :rules="{ required: true, message: '请选择账户类型', trigger: 'change' }"-->
          <!--        >-->
          <!--          <a-select v-model="item.accountType" placeholder="请选择账户类型">-->
          <!--            <a-select-option v-for="(i, key, index) in typeList.accountTypeList" :value="key" :key="index">{{ i }}-->
          <!--            </a-select-option>-->
          <!--          </a-select>-->
          <!--        </a-form-model-item>-->
          <!--      </a-col>-->
          <!--    </a-row>-->
          <!--    <a-row :gutter="10">-->
          <!--      <a-col :span="12">-->
          <!--        <a-form-model-item-->
          <!--          ref="accountName"-->
          <!--          label="账户名称"-->
          <!--          :key="index+'.accountName'"-->
          <!--          :prop="'enterpriseAccountDetailList.' + index + '.accountName'"-->
          <!--          :rules="{ required: true, message: '请输入账户名称', trigger: 'change' }"-->
          <!--        >-->
          <!--          <a-input v-model="item.accountName" @blur="() => { $refs.accountName.onFieldBlur() }"/>-->
          <!--        </a-form-model-item>-->
          <!--      </a-col>-->
          <!--      <a-col :span="12">-->
          <!--        <a-form-model-item-->
          <!--          ref="bankName"-->
          <!--          label="银行名"-->
          <!--          :key="index+'.bankName'"-->
          <!--          :prop="'enterpriseAccountDetailList.' + index + '.bankName'"-->
          <!--          :rules="{ required: true, message: '请输入银行名', trigger: 'change' }"-->
          <!--        >-->
          <!--          <a-input v-model="item.bankName" @blur="() => { $refs.bankName.onFieldBlur() }"/>-->
          <!--        </a-form-model-item>-->
          <!--      </a-col>-->
          <!--    </a-row>-->
          <!--    <a-row :gutter="10">-->
          <!--      <a-col :span="12">-->
          <!--        <a-form-model-item-->
          <!--          ref="accountNo"-->
          <!--          label="账户"-->
          <!--          :key="index+'.accountNo'"-->
          <!--          :prop="'enterpriseAccountDetailList.' + index + '.accountNo'"-->
          <!--          :rules="{ required: true, message: '请输入账户', trigger: 'change' }"-->
          <!--        >-->
          <!--          <a-input v-model="item.accountNo" @blur="() => { $refs.accountNo.onFieldBlur() }"/>-->
          <!--        </a-form-model-item>-->
          <!--      </a-col>-->
          <!--      <a-col :span="12">-->
          <!--        <a-form-model-item-->
          <!--          ref="isEnable"-->
          <!--          label="状态"-->
          <!--          :key="index+'.isEnable'"-->
          <!--          :prop="'enterpriseAccountDetailList.' + index + '.isEnable'"-->
          <!--          :rules="{ required: true, message: '请选择账户状态', trigger: 'change' }"-->
          <!--        >-->
          <!--          <a-radio-group v-model="item.isEnable">-->
          <!--            <a-radio :value="0">禁用</a-radio>-->
          <!--            <a-radio :value="1">启用</a-radio>-->
          <!--          </a-radio-group>-->
          <!--        </a-form-model-item>-->
          <!--      </a-col>-->
          <!--    </a-row>-->
          <!--  </div>-->
          <!--</template>-->
          <!--<a-button v-if="!readOnly" type="dashed" style="margin-left: 60px" @click="addDepartment()">-->
          <!--  <a-icon type="plus-circle" theme="filled" />-->
          <!--  添加账户-->
          <!--</a-button>-->
        </a-form-model>
        <template slot="footer">
          <div>
            <a-button v-if="!readOnly" key="back" @click="(e) => resetForm(e, 'ruleForm')">
              取消
            </a-button>
            <a-button v-if="!readOnly" key="submit" type="primary" @click="(e) => onSubmit( e, 'ruleForm')">
              确认
            </a-button>
          </div>
        </template>
      </a-modal>
    </div>
  </div>

</template>

<script>
import {
  enterpriseAdd,
  enterpriseModify
} from '@/api/enterprise'
import {uploadUserPortrait} from "@/api/user";
export default {
  name: 'CompanyModal',
  props: {
    title: {
      type: String,
      default: ''
    },
    editType: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: () => {}
    },
    typeList: {
      type: Object,
      default: () => {
        return{
          // 主体类型
          subjectTypeList: {},
          // 账户类型
          accountTypeList: {}
        }
      }
    },
  },
  data() {
    return {
      rules: {
        enterpriseName: [{required: true, message: '请输入企业名称', trigger: 'blur'}],
        // systemName: [{required: true, message: '请输入系统名称', trigger: 'blur'}],
        // systemName: [{required: true, message: '请输入系统名字', trigger: 'blur'}],
        // subjectType: [{required: true, message: '请选择主体类型', trigger: 'change'}],
        // accountType: [{required: true, message: '请选择账户类型', trigger: 'change'}],
        // accountName: [{required: true, message: '请输入账户名称', trigger: 'blur'}],
        // accountNo: [{required: true, message: '请输入账户', trigger: 'blur'}],
        // bankName: [{required: true, message: '请输入银行名', trigger: 'blur'}],
        // taxNo: [{required: true, message: '请输入税号', trigger: 'blur'}],
        // description: [{required: true, message: '请输入简介', trigger: 'blur'}],
        // tel: [{required: true, message: '请输入电话号码', trigger: 'blur'}],
        // fax: [{required: true, message: '请输入传真号码', trigger: 'blur'}],
        // email: [{required: true, message: '请输入邮箱', trigger: 'blur'}],
        // postCode: [{required: true, message: '请输入邮编', trigger: 'blur'}],
        // websiteUrl: [{required: true, message: '请输入网址', trigger: 'blur'}],
        // address: [{required: true, message: '请输入地址', trigger: 'blur'}],
        // remark: [{required: true, message: '请输入备注', trigger: 'blur'}],
      },
      confirmLoading: false,
      imgLoading: false,
      isUpload: false,
      file: {},
      fileData: {
        fileCode: null
      }
    }
  },
  methods: {
    // 表单校验
    onSubmit(e, ruleForm) {
      this.$refs[ruleForm].validate(valid => {
        if (valid) {
          if (this.editType === 'add'){
            this.confirmLoading = true
            // const data = {}
            this.form.systemLogoFileCode = this.fileData.fileCode || null
            enterpriseAdd(this.form).then((res) => {
              if (res['code'] !== 0) {
                this.$antMessage.error(res['message'])
                return
              }
              this.$antMessage.success(res['message'])
              this.$emit('closeModal')
              this.confirmLoading = false;
            })
          } else {
            const data = {
              enterpriseId: '',
              enterpriseName: '',
              systemName: '',
              description: '',
              remark: '',
              address: '',
              systemLogoFileCode: '',
              tel: '',
              fax: '',
              postCode: '',
              email: '',
              websiteUrl: ''
              // taxNo: '',
            }
            Object.keys(data).forEach((key) => {
              if (this.form[key]) {
                data[key] = this.form[key]
              }
            })
            data.systemLogoFileCode = this.fileData.fileCode || null
            enterpriseModify(data).then((res) => {
              if (res['code'] !== 0) {
                this.$antMessage.error(res['message'])
                return
              }
              this.$antMessage.success(res['message'])
              this.$emit('closeModal')
              this.confirmLoading = false;
            })
          }

        } else {
          return false;
        }
      });
    },
    // 取消校验
    resetForm(e, ruleForm) {
      this.$refs[ruleForm].resetFields();
      this.$emit('closeModal')
    },
    //
    // // 添加账户
    // addDepartment() {
    //   this.form.enterpriseAccountDetailList.push(
    //     {
    //       subjectType: '',
    //       accountType: '',
    //       accountName: '',
    //       accountNo: '',
    //       bankName: '',
    //       isEnable: 1,
    //     }
    //   )
    // },
    // // 删除账户
    // deleteDepartment(index) {
    //   this.form.enterpriseAccountDetailList.splice(index, 1)
    // },
    // 上传前
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$antMessage.error('您只能上传 JPG 或 png 文件!')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$antMessage.error('上传图片不能超过5M!')
        return false
      }
      this.file = file
      this.isUpload = true
    },
    // 上传文件
    customRequest() {
      this.imgLoading = true
      const data = new FormData()
      data.append('file', this.file)
      data.append('userId', '')
      data.append('enterpriseId', '')
      uploadUserPortrait(data).then((res) => {
        this.imgLoading = false
        if (res['code'] !== 0) {
          this.$antMessage.error(['message'])
          return
        }
        this.fileData = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>

/deep/ .ant-dropdown-menu-item {
  & > a {
    margin: -5px 0px;
    color: #1890ff;
  }
}

///deep/ .ant-modal-wrap.detail-modal {
//  .ant-form-item {
//    margin-bottom: 0px;
//    .form-text {
//      line-height: initial;
//      margin-top: 10px;
//    }
//  }
//}
/deep/ .ant-modal-wrap.edit-modal{
  .ant-modal-content{
    .ant-modal-body{
      max-height: 70vh;
      overflow-y: auto;
    }
    .ant-form{
      .account-list{
      }
      .list-title{
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #e8e8e8;
        .title-name{
          font-weight: 600;
          font-size: 17px;
        }
      }
      .ant-form-item{
        width: 100%;
        margin-bottom: 12px;
        .ant-form-item-label{
          width: 80px;
        }
        .ant-form-item-control-wrapper{
          width: calc( 100% - 80px );
        }
      }
    }
  }
}
/deep/ .ant-modal-wrap.detail-modal{
  .ant-modal-content{
    .ant-modal-body{
      max-height: 70vh;
      overflow-y: auto;
    }
    .ant-form{
      .account-list{
      }
      .list-title{
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #e8e8e8;
        .title-name{
          font-weight: 600;
          font-size: 17px;
        }
      }
      .ant-form-item{
        width: 100%;
        margin-bottom: 12px;
        //.ant-form-item-label{
        //  width: 80px;
        //}
        //.ant-form-item-control-wrapper{
        //  width: calc( 100% - 80px );
        //}
      }
    }
  }
}
</style>
